import React from "react";
import { NavLink,Link } from "react-router-dom";
import arrowRight from '@iconify/icons-mdi/arrow-right';
import { Icon } from "@iconify/react/dist/iconify.js";

const Events = ()=>{
    const data = [
        {
          id: 1,
          title: "Old Painting Restore Workshop",
          place: 'Government Museum, Madurai',
          status:'Completed Events',
          category: 'Workshop',
          description: `Industrialization and globalization have reached all corners of the
          globe, our wars are ever more destructive, and the planet is
          cracking under our pressure. We live in the consumerist era, and
          this might be our downfall. Our civilization’s collapse might
          prove more cataclysmic than that of any ancient culture.`,          
          image: "event2.jpg"
        },
        {
          id: 2,
          title: "Clay sculpting workshop ",
          status:'Completed Events',
          place: 'American College of Arts and Science, Madurai',
          category: 'Workshop',
          description: `Industrialization and globalization have reached all corners of the globe, our wars are ever more destructive, and the planet is
          cracking under our pressure. We live in the consumerist era, and
          this might be our downfall. Our civilization’s collapse might
          prove more cataclysmic than that of any ancient culture.`,          
          image: "event3.JPG"
        },
        {
          id: 3,
          title: " “Parai” Tamil traditional musical instrument making workshop",
          place: 'Kochi Muziris Biennale, Kochi',
          category: 'Workshop',
          status:'Completed Events',
          description: `Industrialization and globalization have reached all corners of the
          globe, our wars are ever more destructive, and the planet is
          cracking under our pressure. We live in the consumerist era, and
          this might be our downfall. Our civilization’s collapse might
          prove more cataclysmic than that of any ancient culture.`,
          image: "event1.jpg"
        },{
          id: 4,
          title: "Chennai Photo Biennale: Screening for Documentary on Keezhadi Excavation labours ",
          place: ' Chennai',
          status:'Completed Events',
          category: 'Screening',
          description: `Industrialization and globalization have reached all corners of the
          globe, our wars are ever more destructive, and the planet is
          cracking under our pressure. We live in the consumerist era, and
          this might be our downfall. Our civilization’s collapse might
          prove more cataclysmic than that of any ancient culture.`,          
          image: "keezhadi.png"
        },
      ];
    return(
        <div className="md:mt-20 sl:mt-4 flex flex-col px-3 md:gap-8">
            <div className="flex items-center justify-between  md:px-40">
                <p className="md:text-4xl md:font-bold text-xl font-semibold curvy-underline">Events</p>
                <NavLink to='/events'>
                    <p className="flex items-center hover:text-pink cursor-pointer gap-2 md:text-lg">
                        See all
                        <span className="p-1 rounded-full border text-pink border-pink">
                            <Icon icon={arrowRight} />
                        </span>
                    </p>
                </NavLink>
            </div>
            <div className='flex flex-wrap  gap-20 md:px-40 py-10'>
    {data.map((event)=>(
    <Link key={event.id} to={`/events/${event.id}`}>
      <div key={event.id} className='flex flex-col border border-gray-200  rounded-md w-[20rem] '>
<div className="relative">
<img className="w-full md:h-72" src={event.image} alt="" />
<div className="bg-pink absolute bottom-0 text-white rounded-r-full w-28 py-1 flex justify-center">
    <span className=" uppercase text-sm font-bold">{event.category}</span>
        </div>
</div>
    <div className='flex flex-col gap-3 py-2'>
      <div className='flex justify-between items-start'>
    <div className='flex items-start w-full flex-col gap-2'>
        <div className="flex flex-col px-4 w-full">
        <span className='text-lg font-semibold line-clamp-2'>{event.title}</span>
        <span className="text-pink text-sm font-semibold">{event.place}</span>
        <span className='text-sm w-full line-clamp-4'>{event.description}</span>
        </div>
        </div>
        {/* <div className="relative flex">
  <div className="rounded-full bg-pink w-4 mr-[-4px] h-4">
  </div>
  <div className="rounded-full bg-black mr-[-4px] w-4 h-4 ">
  </div>
  <div className="rounded-full bg-pink w-4 mr-[-4px] h-4">
  </div>
  <div className="rounded-full bg-black mr-[-4px] w-4 h-4 ">
  </div>
</div> */}
    </div>
 </div>
 </div></Link>
    ))}
</div>
        </div>
    )
}

export default Events;