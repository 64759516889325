import React from "react";
import arrowRight from '@iconify/icons-mdi/arrow-right';
import { Icon } from '@iconify/react';
import { NavLink } from "react-router-dom";

const Exhibitions = () => {
    const data = [
        { id: 1, img: 'vaanam24.JPG', festival:'Vaanam Art Festival - Chennai 2024' },
        { id: 2, img: 'exhibit1.jpg', festival:'The Rooting Nails - New delhi 2023' },
        { id: 3, img: 'vaanam.jpg', festival:'Vaanam Art Festival  - Chennai 2023' },
        { id: 4, img: 'thinaivaasigal.JPG', festival:'Thinai Vaasigal - Kerala 2023' },
        { id: 5, img: 'dalitaesthetics.JPG', festival:'Dalit Aesthetics - Chennai 2022' },
        { id: 6, img: 'vaanam22.jpg', festival:'Vaanam Art Festival  - Chennai 2022' },
    ];
    return (
        <div className=" flex px-5 flex-col gap-4 md:gap-8">
            <div className="flex items-center justify-between md:px-40">
                <p className="md:text-4xl md:font-bold text-lg font-semibold curvy-underline">Exhibitions</p>
                <NavLink to='/exhibitions'>
                    <p className="flex items-center hover:text-pink cursor-pointer gap-2 md:text-lg">
                        See all
                        <span className="p-1 rounded-full border text-pink border-pink">
                            <Icon icon={arrowRight} />
                        </span>
                    </p>
                </NavLink>
            </div>
            <div className="flex flex-wrap gap-20 items-center justify-center">
                {data.map((exhibition) => (
                    <div className="relative h-[18rem]">
                    <NavLink key={exhibition.id} to={`exhibitions/${exhibition.id}`}>
                        <div key={exhibition.id} className="relative cursor-pointer">
                        <img className=" w-[30rem] h-64 md:h-80  " src={exhibition.img} alt={`Exhibition ${exhibition.id}`} />
                        <div className="absolute inset-0 bg-black opacity-0 transition-opacity duration-300 hover:opacity-80 flex items-center justify-center">
                            <p className="text-white text-center md:w-full w-40 md:text-lg font-semibold">{exhibition.festival}</p>
                          
                        </div>
                    </div></NavLink>
                    <p className="absolute text-lg font-semibold bottom-0 rounded-tr-left md:opacity-0 ">{exhibition.festival}</p>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Exhibitions;
