import React from 'react';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import { Icon } from '@iconify/react/dist/iconify.js';

const Carouselo = () => {
  // Define your image URLs directly
  const images = [
    "art11.JPG",
    "art14.JPG",
    "art19.jpg",
    "art1.JPG",
    "art20.JPG",
    "art21.JPG"
  ];

  // Function to chunk the images into groups of three
  const chunkArray = (arr, chunkSize) => {
    const chunkedArray = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
      chunkedArray.push(arr.slice(i, i + chunkSize));
    }
    return chunkedArray;
  };

  // Split images into groups of three
  const chunkedImages = chunkArray(images, 3);

  // Render buttons for next and previous
  const renderNextButton = ({ isDisabled, onClick }) => (
    <button
      className="absolute p-2 flex hover:bg-pink hover:text-white text-pink items-center justify-center bg-gray-100 opacity-0 md:opacity-100 rounded-full xs:invisible md:inline-block md:right-4 md:top-80"
      onClick={onClick}
      disabled={isDisabled}
    >
      <Icon icon="grommet-icons:next" />
    </button>
  );

  const renderBackButton = ({ isDisabled, onClick }) => (
    <button
      className="absolute p-2 hover:bg-pink text-pink hover:text-white bg-gray-100 rounded-full opacity-0 md:opacity-100 left-4 md:top-80"
      onClick={onClick}
      disabled={isDisabled}
    >
      <Icon icon="ic:twotone-arrow-back-ios" />
    </button>
  );

  return (
    <div className='md:h-auto h-[9rem] w-full'>
      <AliceCarousel
        renderNextButton={renderNextButton}
        renderPrevButton={renderBackButton}
        autoPlay
        autoPlayInterval={1500}
        infinite
        mouseTracking
        animationType='slide'
      >
        {chunkedImages.map((slide, slideIndex) => (
          <div key={slideIndex} className='flex gap-2 md:gap-8 justify-center'>
            {slide.map((image, imageIndex) => (
              <img
                key={imageIndex}
                className='h-auto w-auto md:h-[42rem] max-w-[33.33%] md:max-w-none'
                src={image}
                alt={`Slide ${slideIndex * 3 + imageIndex + 1}`}
              />
            ))}
          </div>
        ))}
      </AliceCarousel>
    </div>
  );
};

export default Carouselo;
